module.exports = {
  siteTitle: 'Anna Stepanova', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Anna Stepanova',
  name: 'Anna',
  surname: 'Stepanova',
  subHeading: 'Pianist',

  // social
  socialLinks: [
    // {
    //   icon: 'fa-linkedin-in',
    //   name: 'Linkedin',
    //   url: 'https://linkedin.com/in/',
    // },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/anna.stepanova.35',
    },
  ],
  email: 'anna.piano88@gmail.com',
  phone: '000-00000',
  address: 'Frankfurt',
};
